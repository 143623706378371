<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="2" align-self="center">
            Checks
          </v-col>
          <v-col class="text-center" md="4" align-self="center">
            <date-picker v-model="time1" range value-type="date" placeholder="Select Date Range" />
          </v-col>
          <v-col md="6" align-self="start">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="elevation-1"
                @keyup="searchData"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
              :headers="headers"
              :items="checks"
              :sort-by.sync="sortByKey"
              :sort-desc.sync="sortDesc"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
              @click:row="openReport"
      >
        <template v-slot:no-data>
            <v-progress-circular v-if="isLoading" :size="80" :width="7" color="primary" indeterminate>
              Loading...</v-progress-circular>
          <v-alert v-else color="red" icon="warning">
            {{ errMsg }}
          </v-alert>
        </template>
        <template v-slot:item.status="{ item }">
          <v-btn
                  :to="{name:'CheckDetail',params:{id:item.id}}"
                  block
                  outlined
                  rounded
                  :color="status_colors[item.status]"
                  dark
          >
            <v-icon>{{status_icons[item.status]}}</v-icon>
            {{ item.status }}
          </v-btn>
        </template>
      </v-data-table>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="4" sm="3">
            <v-select
                v-model="pageSize"
                :items="pageSizes"
                label="Items per Page"
                @change="handlePageSizeChange"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="9">
            <v-pagination
                v-model="currentPage"
                :length="totalPages"
                total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageChange"
                class="float-right mt-2"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-card>

  </div>
</template>
<script>
  import {mapActions} from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    components: { DatePicker },
    data () {
      return {
        time1: null,
        date1: null,
        search: '',
        pageSize: 10,
        pageSizes:[10,20,50,100],
        totalPages: 0,
        currentPage: 1,
        sortByKey: 'id',
        sortDesc: true,
        isLoading: true,
        errMsg: 'No Data Found :(',
        headers: [
          {
            text:"Sr. No. ",
            align: "start",
            filterable: true,
            sortable: true,
            value: 'id',
          },
          {
            text: 'Applicant Name',
            align: 'start',
            sortable: true,
            filterable:false,
            value: 'name',
          },

          {
            text: 'CNIC',
            align: 'start',
            sortable: true,
            filterable:false,
            value: 'cnic',
          },
          {
            text: 'Start Time',
            align: 'start',
            sortable: true,
            filterable:false,
            value: 'start_time',
          },
          {
            text: 'Time Consumed',
            align: 'start',
            sortable: true,
            filterable:false,
            value: 'time_consumed',
          },
          {
            text: 'Document Type',
            align: 'start',
            sortable: true,
            filterable:false,
            value: 'document_type',
          },
          {
            text: 'Verified By',
            value: 'agent',
            sortable: true,
            filterable: true,
          },
          {
            text: 'Status',
            value: 'status',
            sortable: true,
            filterable:false,
          },

        ],
        checks:[],
        status_colors:{
          success:"success",
          failed:"error",
          Verified:"success",
          "Not Verified":"error",
          Incomplete: "grey",
          doubtful:"warning",
          Pending: "warning",
          not_started: "error",
        },
        status_icons:{
          success:"done",
          failed:"error",
          Verified:"done",
          "Not Verified":"error",
          Incomplete: "question_mark",
          doubtful:"warning",
          Pending: "warning",
          not_started: "error",
        },
        // status: {
        //   "true": "Verified",
        //   "success": "Verified",
        //   "false": "Not Verified",
        //   "working": "Verified",
        //   "manually authenticated": "Verified"
        // }
      }
    },
    mounted() {
      this.time1 = null
      this.date1 = null
      this.getSessions()
    },
    watch: {
      time1: function (newValue, oldValue) {
        // here you can do whatever you want
        if (newValue !== oldValue) {
          this.date1 = this.time1.map(e => {
            return this.formatDate(e);
          })
          this.getSessions()
        }
      }
    },
    methods: {
      ...mapActions(["setNotification"]),
      formatDate(d) {
        if (d) {
          return `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, 0)}-${d.getDate().toString().padStart(2, 0)}`
        }
        return null
      },
      openReport(value) {
        this.$router.push(`/checks/${value.id}`)
      },
      getSessions(){
        this.isLoading = true
        this.errMsg = 'No Data Found :('
        let url = `/sessions?page_size=${this.pageSize}&page=${this.currentPage}`
        if (this.search.trim() !== '') {
          url = `/sessions?search=${this.search.trim()}&page_size=${this.pageSize}&page=${this.currentPage}`
        }
        // Remove Verified by column in case user is agent
        if (JSON.parse(localStorage.getItem('user')).isSuperUser !== "True"
            && this.headers[6].text === 'Verified By') {
          this.headers.splice(6, 1)
        }
        if (this.date1 !== null && !this.date1.includes(null)) {
          url += `&date=${this.date1}`
        }
        this.$http.get(url).then(res => {
          if (!this.search || (this.search && url.includes(this.search))) {
            this.totalPages = parseInt((res.data.count +  this.pageSize - 1) / this.pageSize)
            if (res.data.results.length > 0) {
              this.checks = res.data.results
              this.checks.forEach(check => {
                const date = new Date(check.created_at)
                const extracted_data = check.report.document_capture && check.report.document_capture.report.extracted_data
                check.name = check.report && extracted_data? extracted_data.Name: null
                check.cnic =  check.report && extracted_data? extracted_data['Identity Number']: null
                check.start_time = date.toDateString() + ", " + date.toLocaleTimeString()
                check.time_consumed = new Date(check.time_consumed * 1000).toISOString().substr(11, 8)
                check.document_type = check.report.document_capture && check.report.document_capture.report.card
                check.agent = check.agent? check.agent.name: 'Unknown'
              })
            } else {
              this.checks = []
              this.isLoading = false
            }
          }
        }).catch(error => {
          this.checks = []
          this.setNotification({
            message: error,
            color: 'error'
          })
          this.isLoading = false
          this.errMsg = error
        })
      },
      handlePageChange(value) {
        this.currentPage = value;
        this.getSessions()
      },
      handlePageSizeChange(size) {
        this.pageSize = size;
        this.currentPage = 1;
        this.getSessions()
      },
      searchData() {
        this.checks = []
        this.isLoading = true
        this.currentPage = 1
        this.getSessions()
      }
    },
  }
</script>
