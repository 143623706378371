<template>
  <div>
    <v-overlay
        z-index="1000"
        :value="overlay"
    >
      <v-progress-circular
          :size="90"
          :width="7"
          color="primary"
          indeterminate
          class="text-center"
      >
        Please Wait...
      </v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          Update Profile
        </span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="8">
              <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                  <v-text-field
                      label="Name"
                      required
                      v-model="client.name"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                >
                  <v-file-input
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Pick an image"
                      prepend-icon="mdi-camera"
                      label="Image"
                      v-model="client.logo"
                  ></v-file-input>
                </v-col>
<!--                <v-col-->
<!--                    cols="12"-->
<!--                    md="12"-->
<!--                >-->
<!--                  <v-text-field-->
<!--                      label="Redirect URL"-->
<!--                      required-->
<!--                      v-model="client.redirect_url"-->
<!--                      :rules="[urlRule]"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
                <v-col cols="12"
                       md="12">
                  <v-text-field
                      label="Webhook URL"
                      required
                      v-model="client.webhook_url"
                      :rules="[urlRule]"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    v-if="hasUndertaking"
                >
                  <v-file-input
                      accept="text/x-markdown"
                      placeholder="Upload Short Undertaking (.md file)"
                      prepend-icon="mdi-file"
                      label="Short Undertaking"
                      v-model="client.short_undertaking"
                  ></v-file-input>
                </v-col>
<!--                <v-col-->
<!--                    cols="12"-->
<!--                    md="12"-->
<!--                    v-if="hasUndertaking"-->
<!--                >-->
<!--                  <v-file-input-->
<!--                      accept="application/pdf"-->
<!--                      placeholder="Upload Detail Undertaking (.pdf file)"-->
<!--                      prepend-icon="mdi-file"-->
<!--                      label="Detail Undertaking"-->
<!--                      v-model="client.detail_undertaking"-->
<!--                  ></v-file-input>-->
<!--                </v-col>-->
  <!--              <v-col v-if="!update" cols="12" sm="6"-->
  <!--                     md="6">-->
  <!--                <v-text-field-->
  <!--                    label="Email"-->
  <!--                    required-->
  <!--                    v-model="client.user.email"-->
  <!--                    :rules="[emailRule]"-->
  <!--                ></v-text-field>-->
  <!--              </v-col>-->
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-row>
                <v-col col="12" md="12" align="center" >
                  <v-img :src="clientLogo"
                         width="400" height="350" class="mb-2" style="border-radius: 10px;"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-row>
        <v-col cols="6" md="6">
          <v-card-actions>
            <v-btn
                v-if="client.remote_verification"
                color="blue darken-1"
                text
                @click="regenerateKey"
            >
              Regenerate Key
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-col>
        <v-col cols="6" md="6">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="updateClient"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
        persistent
        max-width="450"
        v-model="keyDialog"
    >
      <v-card>
        <v-card-title>
          Client key
        </v-card-title>
        <v-container>
          <v-card-text class="text-h6">
            Please Copy this key before closing this window:
          </v-card-text>
          <v-row>
            <v-col>
              <v-card-text class="text--secondary">
                {{ clientKey }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-btn
                  block
                  color="success"
                  @click="copyToClipboard"
              >
                Copy Key
              </v-btn>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-btn block
                     color="error"
                     @click="closeKeyDialog"
              >
                Close Window
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "UpdateProfile",
  data () {
    return {
      client: {},
      overlay: false,
      clientLogo: null,
      clientKey: null,
      keyDialog: false,
      hasUndertaking: false,
      // emailRule: value => {
      //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //   return pattern.test(value.trim()) || 'Invalid e-mail.'
      // },
      urlRule: value => {
        const pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))||((localhost:))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return pattern.test(value) || 'Invalid URL';
      }
    }
  },
  mounted() {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.client) {
        this.client = user.client
        this.clientLogo = user.client.logo
      }
      if (user.client.config && user.client.config.configuration) {
        user.client.config.configuration.forEach(e => {
          if (e.type === "undertaking") {
            this.hasUndertaking = true
          }
        })
      }
    }
  },
  methods: {
    ...mapActions(['setNotification']),
    async updateClient() {
      this.overlay = true
      let clientData = new FormData();
      // if ('user' in clientData && clientData.user) {
      //   delete clientData.user.username
      // }
      for (const [key, value] of Object.entries(this.client)) {
        if (value) {
          console.log(key, value)
          if (key !== 'user') {
            if (key === 'logo') {
              if (value instanceof Blob) {
                let image = await this.getBase64(value)
                clientData.append(key, image)
              }
            } else if (key === 'short_undertaking') {
              if (value instanceof Blob) {
                clientData.append(key, value)
              }
            } else {
              clientData.append(key, value)
            }
          }
        }
      }
      this.$http.patch('/update-client/'+this.client.id+'/', clientData).then(response => {
        const token = localStorage.getItem('token')
        this.$http.get('/user-detail',{
          headers :{
            'Authorization': `token ${token}`
          }
        }).then(res => {
          console.log(res.data)
          localStorage.setItem('user', JSON.stringify(res.data))
          this.client = res.data.client
          this.clientLogo = this.client.logo
          this.$store.commit("setClientLogo", this.clientLogo)
        })
        this.setNotification({
          color: 'success',
          message: 'Client updated successfully'
        })
        this.overlay = false
      }).catch(err => {
        this.overlay = false
        this.setNotification({
          color: 'error',
          message: this.getErrorMessage(err)
        })
      })
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.clientKey);
    },
    closeKeyDialog() {
      this.clientKey = null
      this.keyDialog = false
    },
    regenerateKey() {
      this.overlay = true
      let formData = new FormData();
      formData.append('client', this.client.id);
      this.$http.post("/regenerate-client-key", formData).then(r => {
        console.log(r)
        if (r.data && r.data.key) {
          this.keyDialog = true
          this.clientKey = r.data.key
        }
        this.overlay = false
      }).catch(err => {
        this.setNotification({
          color: 'error',
          message: this.getErrorMessage(err)
        })
        this.overlay = false
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    getErrorMessage(errors) {
      if (errors.response) {
        let message = errors.message
        if (typeof errors.response.data === 'object') {
          errors = errors.response.data
          if ('name' in errors) {
            message = `Name: ${errors['name'][0]}`
          } else if ('cnic' in errors) {
            message = `CNIC: ${errors['cnic'][0]}`
          } else if ('image' in errors) {
            message = `Image: ${errors['image'][0]}`
          } else if ('non_field_errors' in errors) {
            message = `${errors['non_field_errors'][0]}`
          } else if ('user' in errors) {
            let userErrors = errors['user']
            if ('username' in userErrors) {
              message = `Username: ${userErrors['username'][0]}`
            } else if ('email' in userErrors) {
              message = `Email: ${userErrors['email'][0]}`
            } else if ('password' in userErrors) {
              message = `Password: ${userErrors['password'][0]}`
            } else if ('non_field_errors' in userErrors) {
              message = `${userErrors['non_field_errors'][0]}`
            }
          }
        }
        return message
      } else {
        return errors
      }
    },
  }
}
</script>

<style scoped>

</style>