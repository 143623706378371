<template>
  <div>
    <v-overlay
        z-index="1000"
        :value="overlay"
    >
      <v-progress-circular
          :size="90"
          :width="7"
          color="primary"
          indeterminate
          class="text-center"
      >
        Please Wait...
      </v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="4">
            Clients
            <v-spacer></v-spacer>
          </v-col>
          <v-col md="8" class="text-center">
            <v-row>
              <v-col cols="9" md="9">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                />
              </v-col>
              <v-col cols="3" md="3">
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    color="indigo"
                    @click="openCreateModel"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
          :headers="headers"
          :items="clients"
          :search="search"
          :options="{itemsPerPage:8,sortBy:['name']}"
      >
        <template v-slot:item.action="{ item }">
          <div class="d-inline-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    color="primary"
                    v-on="on"
                    @click="regenerateKey(item)"
                >
                  <v-icon>mdi-autorenew</v-icon>
                </v-btn>
              </template>
              <span>Regenerate Key</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    color="primary"
                    v-on="on"
                    @click="editClient(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Update Client</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    color="error"
                    v-on="on"
                    @click="deleteConfirmation = true; currentClientId  = item.id"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete Client</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          :max-width="update ? '700px' : '800px'"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ update ? 'Update Client' : 'Add New Client' }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col
                        cols="12"
                        md="12"
                    >
                      <v-text-field
                          label="Name"
                          required
                          v-model="client.name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                    >
                      <v-file-input
                          accept="image/png, image/jpeg, image/bmp"
                          placeholder="Pick an image"
                          prepend-icon="mdi-camera"
                          label="Logo"
                          v-model="client.logo"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12"
                           md="12">
                      <v-text-field
                          label="Webhook URL"
                          required
                          v-model="client.webhook_url"
                          :rules="[urlRule]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                    >
                      <v-file-input
                          accept="text/x-markdown"
                          placeholder="Upload Short Undertaking (.md file)"
                          prepend-icon="mdi-file"
                          label="Short Undertaking"
                          v-model="client.short_undertaking"
                      ></v-file-input>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                    >
                      <v-textarea
                          label="Config"
                          v-model="client.config"
                      ></v-textarea>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                    >
                      <v-file-input
                          accept="text/x-markdown"
                          placeholder="Upload License File (.lic file)"
                          prepend-icon="mdi-file"
                          label="License"
                          v-model="client.license"
                      ></v-file-input>
                    </v-col>
                    <v-col
                        v-if="!update"
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          label="Username"
                          v-model="client.user.username"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="!update" cols="12" sm="6"
                           md="6">
                      <v-text-field
                          label="Email"
                          required
                          v-model="client.user.email"
                          :rules="[emailRule]"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="!update" cols="12">
                      <v-text-field
                          label="Password"
                          type="password"
                          required
                          v-model="client.user.password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="saveClientData"
            >
              {{ update? 'Update': 'Save' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
        v-model="deleteConfirmation"
        max-width="290"
        persistent
    >
      <v-card>
        <v-card-text class="text-h5">
          Are you sure you want to delete this?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="deleteClient"
          >
            Yes
          </v-btn>

          <v-btn
              color="green darken-1"
              text
              @click="deleteConfirmation = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
        max-width="450"
        v-model="keyDialog"
    >
      <v-card>
        <v-card-title>
          Client key
        </v-card-title>
        <v-container>
          <v-card-text class="text-h6">
            Please Copy this key before closing this window:
          </v-card-text>
          <v-row>
            <v-col>
              <v-card-text class="text--secondary">
                {{ clientKey }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                block
                color="success"
                @click="copyToClipboard"
              >
                Copy Key
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn block
                     color="error"
                     @click="closeKeyDialog"
              >
                Close Window
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Clients",
  data () {
    return {
      dialog: false,
      overlay: false,
      keyDialog: null,
      clientKey: null,
      deleteConfirmation: false,
      search: '',
      update : false,
      headers: [
        {
          text:"Id",
          align: "name",
          filterable: false,
          sortable: true,
          value: 'id',
          width: "2%"
        },
        {
          text:"Name",
          align: "name",
          filterable: true,
          sortable: false,
          value: 'name',
          width: "10%"
        },
        {
          text:"Username",
          align: "name",
          filterable: true,
          sortable: false,
          value: 'user.username',
          width: "10%"
        },
        {
          text: 'Total Verifications',
          value: 'total_verifications',
          sortable: false,
          filterable:false,
        },
        {
          text: 'Verifications Completed',
          value: 'verifications_completed',
          sortable: false,
          filterable:false,
        },
        {
          text: 'Face Liveness',
          value: 'faceliveness_count',
          sortable: false,
          filterable:false,
        },
        {
          text: 'Document Authenticity',
          value: 'document_authenticity_count',
          sortable: false,
          filterable:false,
        },
        {
          text: 'ID Selfie Matching',
          value: 'id_to_selfie_matching_count',
          sortable: false,
          filterable:false,
        },
        {
          text: 'Data Extraction',
          value: 'ocr_count',
          sortable: false,
          filterable:false,
        },
        {
          text: 'Fingerprint Captures',
          value: 'fingerprint_captures',
          sortable: false,
          filterable:false,
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable:false,
        },
      ],
      clients:[],
      emailRule: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value.trim()) || 'Invalid e-mail.'
      },
      urlRule: value => {
        const pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))||((localhost:))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return pattern.test(value.trim()) || 'Invalid URL';
      },
      client :{
        id: '',
        name: '',
        webhook_url: '',
        logo: null,
        short_undertaking: null,
        license: null,
        user: {
          username: '',
          password: '',
          email: '',
        }
      },
      currentClientId: null,
    }
  },
  mounted() {
    window.onbeforeunload = (event => {
      this.onClose(event)
    })
    this.getClients()
  },
  methods: {
    ...mapActions(['setNotification']),
    copyToClipboard() {
      navigator.clipboard.writeText(this.clientKey);
    },
    closeKeyDialog() {
      this.clientKey = null
      this.keyDialog = false
    },
    onClose(event) {
      console.log('unload');
      if (this.keyDialog){
        event.preventDefault()
        event.returnValue = ""
      }
    },
    getClients(){
      this.overlay = true
      this.clients = []
      this.$http.get('/clients').then(response => {
        console.log(response.data)
        this.clients = response.data
        this.overlay = false
      }).catch(err => {
        this.setNotification({
          color: 'error',
          message: this.getErrorMessage(err)
        })
        this.overlay = false
      })
    },
    openCreateModel() {
      this.dialog = true
      this.update = false
      this.client = {
        id: '',
        name: '',
        webhook_url: '',
        logo: null,
        short_undertaking: null,
        license: null,
        user: {
          username: '',
          password: '',
          email: '',
        }
      }
    },
    async saveClientData() {
      if (this.validateClientData()) {
        let clientData = new FormData();
        for (const [key, value] of Object.entries(this.client)) {
          if (value) {
            console.log(key)
            if (key === 'logo' && value instanceof Blob) {
              console.log('converting image')
              let image = await this.getBase64(value)
              clientData.append(key, image)
            } else if (key === 'user') {
              clientData.append("stringified_user", JSON.stringify(value))
            } else {
              clientData.append(key, value)
            }
          }
        }
        if (this.update) {
          this.updateClient(clientData)
        } else {
          this.createClient(clientData)
        }
      }
    },
    createClient(clientData) {
      this.overlay = true
      console.log('-- client data --', JSON.stringify(Object.fromEntries(clientData)))
      this.$http.post('/clients/', clientData).then(response => {
        if (response && response.data) {
          console.log(response.data)
          this.dialog = false
          if (response.data.key) {
            this.keyDialog = true
            this.clientKey = response.data.key
          }
          this.setNotification({
            color: 'success',
            message: 'Client created successfully'
          })
          this.getClients()
        }
      }).catch(err => {
        this.setNotification({
          color: 'error',
          message: this.getErrorMessage(err)
        })
        this.overlay = false
      })
    },
    updateClient(clientData) {
      this.overlay = true
      if ('user' in clientData && clientData.user) {
        delete clientData.user.username
      }
      console.log(clientData)
      this.$http.patch('/clients/'+this.currentClientId+'/', clientData).then(response => {
        this.dialog = false
        this.setNotification({
          color: 'success',
          message: 'Client updated successfully'
        })
        this.getClients()
      }).catch(err => {
        this.setNotification({
          color: 'error',
          message: this.getErrorMessage(err)
        })
        this.overlay = false
      })
    },
    validateClientData() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const urlPattern = new RegExp('^(https?:\\/\\/)'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))||((localhost:))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      console.log(this.client)
      if (this.client.name.trim() === "") {
        this.setNotification({
          color: 'error',
          message: 'Please fill name first'
        })
        return false
      }
      if (this.client.webhook_url && this.client.webhook_url.trim() !== "" && !urlPattern.test(this.client.webhook_url.trim())) {
        this.setNotification({
          color: 'error',
          message: 'Please enter valid Webhook URL'
        })
        return false
      }
      // if (this.client.webhook_url.trim() === "") {
      //   this.setNotification({
      //     color: 'error',
      //     message: 'Please enter Webhook Url'
      //   })
      //   return false
      // }
      if (!this.update) {
        if (this.client.user.username.trim() === "") {
          this.setNotification({
            color: 'error',
            message: 'Please fill username first'
          })
          return false
        }
        if (this.client.user.email.trim() === "") {
          this.setNotification({
            color: 'error',
            message: 'Please fill email first'
          })
          return false
        }
        if (!pattern.test(this.client.user.email.trim())) {
          this.setNotification({
            color: 'error',
            message: 'Please enter valid email'
          })
          return false
        }
        if (this.client.user.password.trim() === "") {
          this.setNotification({
            color: 'error',
            message: 'Please fill password first'
          })
          return false
        }
      }
      return true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    regenerateKey(item) {
      this.overlay = true
      let formData = new FormData();
      formData.append('client', item.id);
      this.$http.post("/regenerate-client-key", formData).then(r => {
        console.log(r)
        if (r.data && r.data.key) {
          this.keyDialog = true
          this.clientKey = r.data.key
        }
        this.overlay = false
      }).catch(err => {
        this.setNotification({
          color: 'error',
          message: this.getErrorMessage(err)
        })
      })
    },
    editClient(data) {
      let dataCopy = JSON.parse(JSON.stringify(data))
      this.update = true
      this.client = dataCopy
      this.currentClientId = dataCopy.id
      delete this.client.logo
      if (this.client.short_undertaking) {
        delete this.client.short_undertaking
      }
      if (this.client.license) {
        delete this.client.license
      }
      this.client.config = JSON.stringify(this.client.config)
      this.dialog = true
    },
    deleteClient() {
      this.overlay = true
      this.$http.delete('/clients/'+this.currentClientId+'/').then(response => {
        this.deleteConfirmation = false
        this.setNotification({
          color: 'success',
          message: 'Client deleted successfully'
        })
        this.getClients()
      }).catch(err => {
        this.setNotification({
          color: 'error',
          message: err.message
        })
        this.overlay = false
      })
    },
    getErrorMessage(errors) {
      if (errors.response) {
        let message = errors.message
        if (typeof errors.response.data === 'object') {
          errors = errors.response.data
          if ('name' in errors) {
            message = `Name: ${errors['name'][0]}`
          } else if ('cnic' in errors) {
            message = `CNIC: ${errors['cnic'][0]}`
          } else if ('image' in errors) {
            message = `Image: ${errors['image'][0]}`
          } else if ('non_field_errors' in errors) {
            message = `${errors['non_field_errors'][0]}`
          } else if ('user' in errors) {
            let userErrors = errors['user']
            if ('username' in userErrors) {
              message = `Username: ${userErrors['username'][0]}`
            } else if ('email' in userErrors) {
              message = `Email: ${userErrors['email'][0]}`
            } else if ('password' in userErrors) {
              message = `Password: ${userErrors['password'][0]}`
            } else if ('non_field_errors' in userErrors) {
              message = `${userErrors['non_field_errors'][0]}`
            }
          }
        }
        return message
      } else {
        return errors
      }
    },
  },
}
</script>

<style scoped>

</style>