<template>
  <div>
    <v-card>
      <v-card-title>
        Checks
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="elevation-1"
            @keyup="searchData"
        ></v-text-field>
      </v-card-title>

      <v-data-table
          :headers="headers"
          :items="checks"
          :sort-by.sync="sortByKey"
          :sort-desc.sync="sortDesc"
          disable-pagination
          :hide-default-footer="true"
          class="elevation-1"
          @click:row="openReport"
      >
        <template v-slot:no-data>
          <v-progress-circular v-if="isLoading" :size="80" :width="7" color="primary" indeterminate>
            Loading...</v-progress-circular>
          <v-alert v-else color="red" icon="warning">
            {{ errMsg }}
          </v-alert>
        </template>
        <template v-slot:item.status="{ item }">
          <v-btn
              :to="{name:'CheckDetail',params:{id:item.id}}"
              block
              outlined
              rounded
              :color="status_colors[item.status]"
              dark
          >
            <v-icon>{{status_icons[item.status]}}</v-icon>
            {{ item.status }}
          </v-btn>
        </template>
      </v-data-table>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="4" sm="3">
            <v-select
                v-model="pageSize"
                :items="pageSizes"
                label="Items per Page"
                @change="handlePageSizeChange"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="9">
            <v-pagination
                v-model="currentPage"
                :length="totalPages"
                total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageChange"
                class="float-right mt-2"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-card>

  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data () {
    return {
      search: '',
      pageSize: 10,
      pageSizes:[10,20,50,100],
      totalPages: 0,
      currentPage: 1,
      sortByKey: 'id',
      sortDesc: true,
      isLoading: true,
      errMsg: 'No Data Found :(',
      headers: [
        {
          text:"Sr. No. ",
          align: "start",
          filterable: true,
          sortable: true,
          value: 'id',
        },
        {
          text: 'Applicant Name',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'name',
        },

        {
          text: 'CNIC',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'cnic',
        },
        {
          text: 'Start Time',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'start_time',
        },
        // {
        //   text: 'Time Consumed',
        //   align: 'start',
        //   sortable: true,
        //   filterable:false,
        //   value: 'time_consumed',
        // },
        {
          text: 'Document Type',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'document_type',
        },
        {
          text: 'Verified By',
          value: 'agent',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true,
          filterable:false,
        },

      ],
      checks:[],
      status_colors:{
        success:"success",
        failed:"error",
        Verified:"success",
        "Not Verified":"error",
        Incomplete: "grey",
        doubtful:"warning",
        Pending: "warning",
        not_started: "error",
      },
      status_icons:{
        success:"done",
        failed:"error",
        Verified:"done",
        "Not Verified":"error",
        Incomplete: "question_mark",
        doubtful:"warning",
        Pending: "warning",
        not_started: "error",
      },
      // status: {
      //   "true": "Verified",
      //   "success": "Verified",
      //   "false": "Not Verified",
      //   "working": "Verified",
      //   "manually authenticated": "Verified"
      // }
    }
  },
  mounted() {
    this.getSessions()
  },
  watch: {
    '$store.state.sessionNotification.allSessions': function() {
      this.getSessions()
    }
  },
  methods: {
    ...mapActions(["setNotification"]),
    openReport(value) {
      this.$router.push(`/checks/${value.id}`)
    },
    getSessions(){
      this.isLoading = true
      this.errMsg = 'No Data Found :('
      let url = `/sessions?page_size=${this.pageSize}&page=${this.currentPage}&pending=true`
      if (this.search.trim() !== '') {
        url = `/sessions?search=${this.search.trim()}&page_size=${this.pageSize}&page=${this.currentPage}&pending=true`
      }
      // Remove Verified by column in case user is agent
      if (JSON.parse(localStorage.getItem('user')).isSuperUser !== "True"
          && this.headers[6].text === 'Verified By') {
        this.headers.splice(6, 1)
      }
      this.$http.get(url).then(res => {
        if (!this.search || (this.search && url.includes(this.search))) {
          this.totalPages = parseInt((res.data.count +  this.pageSize - 1) / this.pageSize)
          if (res.data.results.length > 0) {
            this.checks = res.data.results
            this.checks.forEach(check => {
              const date = new Date(check.created_at)
              const extracted_data = check.report.document_capture && check.report.document_capture.report.extracted_data
              check.name = check.report && extracted_data? extracted_data.Name: null
              check.cnic =  check.report && extracted_data? extracted_data['Identity Number']: null
              check.start_time = date.toDateString() + ", " + date.toLocaleTimeString()
              check.document_type = check.report.document_capture && check.report.document_capture.report.card
              check.agent = check.agent? check.agent.name: 'Unknown'
            })
          } else {
            this.checks = []
            this.isLoading = false
          }
        }
      }).catch(error => {
        this.checks = []
        this.setNotification({
          message: error,
          color: 'error'
        })
        this.isLoading = false
        this.errMsg = error
      })
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.getSessions()
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.getSessions()
    },
    searchData() {
      this.checks = []
      this.isLoading = true
      this.currentPage = 1
      this.getSessions()
    }
  },
}
</script>
