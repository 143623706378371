<template>
  <v-img :src="imageUrl? imageUrl: require('@/assets/not_found.png')"
         :lazy-src="require(lazyImage === 'cnic'? '@/assets/cnic.jpg':
         lazyImage === 'face'? '@/assets/face_placeholder.png': '@/assets/fingerprint.png')"
         :aspect-ratio=aspectRatio
         :width="width"
         :height="height"
         class="mb-2" :class="styles">
    <template v-slot:placeholder>
      <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
      >
        <v-progress-circular indeterminate color="grey lighten-5" />
      </v-row>
    </template>
  </v-img>
</template>

<script>
export default {
  name: "imageLazy",
  props: {
    imageUrl: String,
    aspectRatio: String,
    lazyImage: String,
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    styles: {
      type: String,
      default: 'face-style'
    }
  }
}
</script>

<style scoped>
.face-style {
  border-radius: 5%;
  margin: auto;
}
.card-style {
  border-radius: 10px;
}
</style>