<template>
  <div>
    <v-overlay
        z-index="1000"
        :value="overlay"
    >
      <v-progress-circular
          :size="90"
          :width="7"
          color="primary"
          indeterminate
          class="text-center"
      >
        Please Wait...
      </v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="4">
            Agents
            <v-spacer></v-spacer>
          </v-col>
          <v-col md="8" class="text-center">
            <v-row>
              <v-col cols="9" md="9">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                />
              </v-col>
              <v-col cols="3" md="3">
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    color="indigo"
                    @click="openCreateModel"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
          :headers="headers"
          :items="agents"
          :search="search"
          :options="{itemsPerPage:8,sortBy:['name']}"
      >
        <template v-slot:item.action="{ item }">
          <div class="d-inline-flex">
            <v-btn
                icon
                color="primary"
                @click="editAgent(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
                icon
                color="error"
                @click="deleteConfirmation = true; currentAgentId  = item.id"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          :max-width="update ? '1200px' : '800px'"
      >
        <v-card>
          <v-form @submit.prevent="saveAgentData">
            <v-card-title>
            <span class="text-h5">
              {{ update? 'Update Agent' : 'Add New Agent'}}
            </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" :md="update ? '9' : '12'">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            label="Name"
                            required
                            v-model="agent.name"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            label="CNIC"
                            v-mask="'#####-#######-#'"
                            v-model="agent.cnic"
                            :rules="[cnicRule]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          md="9"
                      >
                        <v-file-input
                            accept="image/png, image/jpeg, image/bmp"
                            placeholder="Pick an image"
                            prepend-icon="mdi-camera"
                            label="Image"
                            v-model="agent.image"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12"
                             md="3">
                        <v-container
                            class="px-0"
                            fluid
                        >
                          <v-checkbox
                              v-model="agent.createUser"
                              label="Create User"
                          ></v-checkbox>
                        </v-container>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          v-if="agent.createUser"
                      >
                        <v-text-field
                            label="Username"
                            v-model="agent.user.username"
                            :disabled="update && agents.find(ag => ag.id === agent.id).createUser"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6"
                             md="6" v-if="agent.createUser">
                        <v-text-field
                            label="Email"
                            required
                            v-model="agent.user.email"
                            :rules="[emailRule]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="agent.createUser && !(update && agents.find(ag => ag.id === agent.id).createUser)">
                        <v-text-field
                            label="Password"
                            type="password"
                            required
                            v-model="agent.user.password"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="update" col="12" md="3" align="center" >
                    <v-img :src="agentImage"
                           width="400" height="350" class="mb-2" style="border-radius: 10px;"></v-img>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  type="submit"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar
        v-model="snackbar.display"
        :color="snackbar.color"
        timeout="2000"
        right
        top
    >
      {{ snackbar.text }}
    </v-snackbar>
    <v-dialog
        v-model="deleteConfirmation"
        max-width="290"
        persistent
    >
      <v-card>
        <v-card-text class="text-h5">
          Are you sure you want to delete this?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="deleteAgent"
          >
            Yes
          </v-btn>

          <v-btn
              color="green darken-1"
              text
              @click="deleteConfirmation = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Agents",
  data () {
    return {
      dialog: false,
      overlay: false,
      deleteConfirmation: false,
      search: '',
      update : false,
      headers: [
        {
          text:"Name",
          align: "name",
          filterable: true,
          sortable: false,
          value: 'name',
          width: "33%"
        },
        {
          text:"Username",
          align: "name",
          filterable: true,
          sortable: false,
          value: 'user.username',
          width: "33%"
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable:false,
        },
      ],
      agents:[],
      emailRule: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value.trim()) || 'Invalid e-mail.'
      },
      cnicRule: value => {
        const pattern = /^[0-9]{5}-[0-9]{7}-[0-9]$/
        return pattern.test(value.trim()) || 'Invalid cnic.'
      },
      agent :{
        id: '',
        name: '',
        image: null,
        cnic: '',
        createUser: true,
        user: {
          username: '',
          password: '',
          email: '',
        }
      },
      currentAgentId: null,
      snackbar: {
        color: '',
        display: false,
        text: ''
      },
      agentImage: null,
    }
  },
  mounted() {
    this.getAgents()
  },
  methods: {
    ...mapActions(['setNotification']),
    getAgents(){
      this.overlay = true
      this.$http.get('/agents').then(response => {
        this.agents = response.data
        this.agents.forEach(agent =>{
          if (agent.user && agent.user.username !== "") {
            agent.createUser = true
          } else {
            agent.createUser = false
            agent.user = {
              username: '',
              email: '',
              password: ''
            }
          }
        })
        this.overlay = false
      }).catch(err => {
        this.setNotification({
          color: 'error',
          message: this.getErrorMessage(err)
        })
        this.overlay = false
      })
    },
    openCreateModel() {
      this.dialog = true
      this.update = false
      this.agent = {
        id: '',
        name: '',
        image: null,
        cnic: '',
        createUser: true,
        user: {
          username: '',
          password: '',
          email: '',
        }
      }
    },
    saveAgentData() {
      if (this.validateAgentData()) {
        let agentData = JSON.parse(JSON.stringify(this.agent))
        if (!agentData.createUser) {
          delete agentData.user
        }
        if (agentData.image !== null) {
          this.getBase64(this.agent.image).then(data => {
            agentData.image = data
            this.createOrUpdateAgent(agentData)
          })
        } else {
          this.createOrUpdateAgent(agentData)
        }
      }
    },
    createOrUpdateAgent(agentData) {
      if (this.update) {
        this.updateAgent(agentData)
      } else {
        this.createAgent(agentData)
      }
    },
    createAgent(agentData) {
      this.overlay = true
      this.$http.post('/agents/', agentData).then(response => {
        this.dialog = false
        this.setNotification({
          color: 'success',
          message: 'Agent created successfully'
        })
        this.getAgents()
      }).catch(err => {
        this.setNotification({
          color: 'error',
          message: this.getErrorMessage(err)
        })
        this.overlay = false
      })
    },
    updateAgent(agentData) {
      this.overlay = true
      if (agentData.image === null) {
        delete agentData.image
      }
      if (this.agents.find(ag => ag.id === this.agent.id).createUser) {
        delete agentData.user.username
      }
      if (!agentData.createUser) {
        delete agentData.user
      }
      this.$http.patch('/agents/'+this.currentAgentId+'/', agentData).then(response => {
        this.dialog = false
        this.snackbar = {
          color: 'success',
          display: true,
          text: 'Agent updated successfully'
        }
        this.getAgents()
      }).catch(err => {
        this.snackbar = {
          color: 'error',
          display: true,
          text: this.getErrorMessage(err)
        }
        this.overlay = false
      })
    },
    validateAgentData() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const cnicPattern = /^[0-9]{5}-[0-9]{7}-[0-9]$/
      if (this.agent.name.trim() === "") {
        this.snackbar = {
          color: '#C62828',
          display: true,
          text: 'Please fill name first'
        }
        return false
      }
      if (!cnicPattern.test(this.agent.cnic.trim())) {
        this.snackbar = {
          color: '#C62828',
          display: true,
          text: 'Please enter valid CNIC'
        }
        return false
      }
      if (this.agent.createUser && this.agent.user.username.trim() === "") {
        this.snackbar = {
          color: '#C62828',
          display: true,
          text: 'Please fill username first'
        }
        return false
      }
      if (this.agent.createUser && this.agent.user.email.trim() === "") {
          this.snackbar = {
            color: '#C62828',
            display: true,
            text: 'Please fill email first'
          }
          return false
      }
      if (this.agent.createUser && !pattern.test(this.agent.user.email.trim())) {
        this.snackbar = {
          color: '#C62828',
          display: true,
          text: 'Please enter valid email'
        }
        return false
      }
      if (this.agent.createUser && !this.update && this.agent.user.password.trim() === "") {
        this.snackbar = {
          color: '#C62828',
          display: true,
          text: 'Please fill password first'
        }
        return false
      }
      if (!this.update && this.agent.image === null) {
        this.snackbar = {
          color: '#C62828',
          display: true,
          text: 'Please select image first'
        }
        return false
      }
      return true

    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    editAgent(data) {
      let dataCopy = JSON.parse(JSON.stringify(data))
      this.update = true
      this.agent = dataCopy
      this.agentImage = dataCopy.image
      this.agent.image = null
      this.currentAgentId = dataCopy.id
      this.dialog = true
    },
    deleteAgent() {
      this.overlay = true
      this.$http.delete('/agents/'+this.currentAgentId+'/').then(response => {
        this.deleteConfirmation = false
        this.snackbar = {
          color: 'success',
          display: true,
          text: 'Agent deleted successfully'
        }
        this.update = false
        this.agent = {
          id: '',
          name: '',
          image: null,
          cnic: '',
          createUser: true,
          user: {
            username: '',
            password: '',
            email: '',
          }
        }
        this.getAgents()
      }).catch(err => {
        this.snackbar = {
          color: 'error',
          display: true,
          text: err.message
        }
        this.overlay = false
      })
    },
    getErrorMessage(errors) {
      if (errors.response) {
        let message = errors.message
        if (typeof errors.response.data === 'object') {
          errors = errors.response.data
          if ('name' in errors) {
            message = `Name: ${errors['name'][0]}`
          } else if ('cnic' in errors) {
            message = `CNIC: ${errors['cnic'][0]}`
          } else if ('image' in errors) {
            message = `Image: ${errors['image'][0]}`
          } else if ('non_field_errors' in errors) {
            message = `${errors['non_field_errors'][0]}`
          } else if ('user' in errors) {
            let userErrors = errors['user']
            if ('username' in userErrors) {
              message = `Username: ${userErrors['username'][0]}`
            } else if ('email' in userErrors) {
              message = `Email: ${userErrors['email'][0]}`
            } else if ('password' in userErrors) {
              message = `Password: ${userErrors['password'][0]}`
            } else if ('non_field_errors' in userErrors) {
              message = `${userErrors['non_field_errors'][0]}`
            }
          }
        }
        return message
      } else {
        return errors
      }
    },
  },
}
</script>

<style scoped>

</style>