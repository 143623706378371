<template>
  <div>
    <v-overlay
        z-index="100"
        :value="overlay"
    >
      <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
      />
    </v-overlay>
    <v-card>
      <v-card>
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="">
            <v-row align="center">
              <v-col cols="12" md="4">
                <h2>Customer Verification Report</h2>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col md="3">
                    <span class="span-text">  Name: </span>
                  </v-col>
                  <v-col md="9" align="left">
                    <h4>{{ extracted_data.Name }}</h4>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                    block
                    outlined
                    rounded
                    :color="status_colors[overAllStatus]"
                    dark
                >
                  <v-icon>{{status_icons[overAllStatus]}}</v-icon>
                  {{ overAllStatus }}
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-card>
      <v-expansion-panels multiple v-model="panel" v-if="check.report" style="margin-top: 10px">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="12">
                <h3 class="panel-heading">Customer Location and IP Address</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="8">
                <v-list>
                  <v-list-item>
                    <span class="span-text">  IP Address: </span>
                    <span class="span-text ml-1">  {{ check.report.ip_address || 'Not Found' }}</span>
                  </v-list-item>
                  <v-list-item>
                    <span class="span-text">  Latitude: </span>
                    <span class="span-text ml-1">  {{ check.report.latitude || 'Not Found' }}</span>
                  </v-list-item>
                  <v-list-item>
                    <span class="span-text">  Longitude: </span>
                    <span class="span-text ml-1">  {{ check.report.longitude || 'Not Found' }}</span>
                  </v-list-item>
                  <v-list-item>
                    <span class="span-text">  Device: </span>
                    <span class="span-text ml-1">  {{ check.parsed_user_agent || 'Not Found' }}</span>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col md="4">
                <l-map
                    ref="myMap"
                    v-if="center"
                    :zoom="zoom"
                    :center="center"
                    :options="mapOptions"
                    style="height: 208px !important;"
                >
                  <l-tile-layer
                      :url="url"
                      :attribution="attribution"
                  />
                  <l-marker :lat-lng="center" />
                </l-map>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.agent_verification">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Agent Liveness Detection</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[check.report.agent_verification.status]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[check.report.agent_verification.status] }}
                    </v-icon>
                    {{ status[check.report.agent_verification.status] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="[true, false].includes(agentLiveVerified)">
            <v-row>
              <v-col cols="12" :md="agentPoseFrameUrl? 6: 8">
                <v-list v-if="check.report.agent_verification">
                  <v-list-item>
                    <span class="span-text">  Name: </span>
                    <span class="span-text ml-1">  {{ agentName }}</span>
                  </v-list-item>
                  <v-list-item>
                    <span class="span-text">  CNIC: </span>
                    <span class="span-text ml-1">  {{ agentCnic }}</span>
                  </v-list-item>
                  <v-list-item v-if="agentPoseFrameUrl && check.report.agent_verification.challenge">
                    <span class="span-text">Challenge: {{ check.report.agent_verification.challenge }}</span>
                  </v-list-item>
                  <v-list-item v-if="agentPoseFrameUrl && check.report.agent_verification.challenge_pose">
                    <span class="span-text">Challenge Pose: {{ check.report.agent_verification.challenge_pose }}</span>
                  </v-list-item>
                  <v-list-item
                      v-for="[key, val] in Object.entries(agent_verification)"
                      class="not-clickable"
                      :key="key">
                    <v-tooltip top :disabled="!val.message">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :color="val.icon_color" class="mr-1" size="x-large">
                          {{ val.icon_text }}
                        </v-icon>
                        <span
                            v-bind="attrs"
                            v-on="on"
                            class="span-text">{{ toTitleCase(key) }}</span>
                      </template>
                      <span v-if="val.message">{{ val.message }}</span>
                    </v-tooltip>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col md="2" align="center">
                <image-lazy :image-url="agentReferenceUrl" lazy-image="face" aspect-ratio="1"
                            width="150px" height="150px" />
                <span class="span-text">Reference</span>
              </v-col>
              <v-col md="2" align="center">
                <image-lazy :image-url="agentFrameUrl" lazy-image="face" aspect-ratio="1"
                            width="150px" height="150px" />
                <span class="span-text">Front Frame</span>
              </v-col>
              <v-col v-if="agentPoseFrameUrl" md="2" align="center">
                <image-lazy :image-url="agentPoseFrameUrl" lazy-image="face" aspect-ratio="1"
                            width="150px" height="150px" />
                <span class="span-text">Pose Frame</span>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.face_liveness">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Customer Liveness Detection</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[customerLiveVerified]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[customerLiveVerified] }}
                    </v-icon>
                    {{ status[customerLiveVerified] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" :md="poseFrameUrl? 8: 10">
                <v-list v-if="face_liveness">
                  <v-list-item v-if="poseFrameUrl && check.report.face_liveness.challenge">
                    <span class="span-text">Challenge: {{ check.report.face_liveness.challenge }}</span>
                  </v-list-item>
                  <v-list-item v-if="poseFrameUrl && check.report.face_liveness.challenge_pose">
                    <span class="span-text">Challenge Pose: {{ check.report.face_liveness.challenge_pose }}</span>
                  </v-list-item>
                  <v-list-item
                      v-for="[key, val] in Object.entries(face_liveness)"
                      class="not-clickable"
                      :key="key">
                    <v-tooltip top :disabled="!val.message">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :color="val.icon_color" class="mr-1" size="x-large">
                          {{ val.icon_text }}
                        </v-icon>
                        <span
                            v-bind="attrs"
                            v-on="on"
                            class="span-text">{{ toTitleCase(key) }}</span>
                      </template>
                      <span v-if="val.message">{{ val.message }}</span>
                    </v-tooltip>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col md="2" class="text-center">
                <image-lazy :image-url="faceFrameUrl" lazy-image="face" aspect-ratio="1"
                            width="150px" height="150px" />
                <span class="span-text">  Face Frame </span>
              </v-col>
              <v-col v-if="poseFrameUrl" md="2" class="text-center">
                <image-lazy :image-url="poseFrameUrl" lazy-image="face" aspect-ratio="1"
                            width="150px" height="150px" />
                <span class="span-text">  Pose Frame </span>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.document_capture">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Customer Document Capture</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[check.report.document_capture.status]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[check.report.document_capture.status] }}
                    </v-icon>
                    {{ status[check.report.document_capture.status] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row id="documentCapture">
              <v-col cols="12" md=8>
                <v-list-item>
                  <span class="span-text">  Document Type: </span>
                  <span class="span-text ml-1">  {{ check.report.document_capture.report.card }}</span>
                </v-list-item>
                <v-list-item
                    v-for="[key, val] in Object.entries(document_capture)"
                    :key="key">
                  <v-tooltip top :disabled="!val.message">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :color="val.icon_color" class="mr-1" size="x-large">
                        {{ val.icon_text }}
                      </v-icon>
                      <span
                          v-bind="attrs"
                          v-on="on"
                          class="span-text">{{ toTitleCase(key) }}</span>
                    </template>
                    <span v-if="val.message">{{ val.message }}</span>
                  </v-tooltip>
                </v-list-item>
              </v-col>
              <v-col md="4" align="center">
                <v-row class="mr-2">
                  <image-lazy :image-url="referenceFrameUrl" lazy-image="cnic" aspect-ratio="1000/641"
                              styles="card-style" />
                </v-row>
                <v-row class="justify-content-center">
                  <p>Front Side</p>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.tamper_frames && check.tamper_frames.length > 0" style="height: 100%;">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Tampering Check Frames</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                  v-for="each in check.tamper_frames"
                  :key="each.frame"
                  cols="12" md="4"
              >
                <image-lazy :image-url="each.frame" lazy-image="cnic" aspect-ratio="1000/641"
                            styles="card-style" />
                <p class="text-center" v-if="each.label">Label: {{ each.label }}</p>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.document_backside_capture">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Customer Document Backside Capture</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[check.report.document_backside_capture.status]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[check.report.document_backside_capture.status] }}
                    </v-icon>
                    {{ status[check.report.document_backside_capture.status] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row id="documentCapture">
              <v-col cols="12" md=8>
                <v-list-item>
                  <span class="span-text">  Document Type: </span>
                  <span class="span-text ml-1">  {{ check.report.document_capture.report.card }}</span>
                </v-list-item>
                <v-list-item>
                  <span class="span-text">  QR Data: </span>
                  <span class="span-text ml-1">{{ qrCode }}</span>
                </v-list-item>
                <v-list-item v-if="qrCode && qrCode !== 'Not Found'">
                  <div v-if="check.report.document_backside_capture.status === true">
                    <v-icon color="success" class="mr-1" size="x-large">check_circle</v-icon>
                    <span class="span-text">QR Data Matched</span>
                  </div>
                  <div v-else-if="check.report.document_backside_capture.status === false">
                    <v-icon color="red" class="mr-1" size="x-large">cancel</v-icon>
                    <span class="span-text">QR Data Not Matched</span>
                  </div>
                </v-list-item>
              </v-col>
              <v-col md="4" align="center">
                <v-row class="mr-2">
                  <image-lazy :image-url="backFrameUrl" lazy-image="cnic" aspect-ratio="1000/641" styles="card-style" />
                </v-row>
                <v-row class="justify-content-center">
                  <p>Back Side</p>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.document_authenticity">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Customer Document Authenticity</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[customerDocVerified]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[customerDocVerified] }}
                    </v-icon>
                    {{ status[customerDocVerified] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row id="documentAuthenticity">
              <v-col cols="12" md="4">
                <v-list-item
                    v-if="customerDocVerified === 'manually authenticated'">
                  <span class="span-text">Manually Verified by Agent!!</span>
                </v-list-item>
                <v-list-item
                    v-else-if="customerDocVerified === 'manually authenticated by VO'">
                  <span class="span-text">Manually Verified by Verification Officer!!</span>
                </v-list-item>
                <v-list-item
                    v-else
                    v-for="[key, val] in Object.entries(document_authenticity)"
                    :key="key">
                  <v-icon :color="val.icon_color" class="mr-1" size="x-large">
                    {{ val.icon_text }}
                  </v-icon>
                  <span class="span-text">{{ toTitleCase(key) }}</span>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="4" align="center">
                <v-row v-if="customerDocVerified === 'pending' && this.isVerificationOfficer">
                  <v-col md="12" align="center">
                    <span>Do you want to verify?</span>
                  </v-col>
                </v-row>
                <v-row v-if="customerDocVerified === 'pending' && this.isVerificationOfficer">
                  <v-col md="6">
                    <v-btn class="mdi-format-float-center w-50" block
                           :class="manualOverride === true? 'bg-success': ''"
                           :color="manualOverride !== true? 'success': ''"
                           outlined
                           rounded
                           @click="changeDocumentStatus(true)"
                    >
                      <v-icon>{{status_icons.Verified}}</v-icon>
                      Yes
                    </v-btn>
                  </v-col>
                  <v-col md="6">
                    <v-btn class="mdi-format-float-center w-50" block
                           :class="manualOverride === false? 'bg-fail': ''"
                           :color="manualOverride !== false? 'red': ''"
                           outlined
                           rounded
                           @click="changeDocumentStatus(false)"
                    >
                      <v-icon>{{status_icons["Not Verified"]}}</v-icon>
                      No
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="check && check.authentication_video" cols="12" md="4" align="center">
                <video style="border-radius: 10px;width: 100%" controls autoplay :src="check.authentication_video"/>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.authentication_frames && check.authentication_frames.length > 0" style="height: 100%;">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Authentication Frames</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                  v-for="each in check.authentication_frames"
                  :key="each.frame"
                  cols="12" md="4"
              >
                <image-lazy :image-url="each.frame" lazy-image="cnic" aspect-ratio="1000/641"
                            styles="card-style" />
                <p class="text-center" v-if="each.confidence">Confidence: {{ each.confidence }}</p>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.id_to_selfie_matching" style="height: 100%;">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">ID-Selfie Matching</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[check.report.id_to_selfie_matching.status]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[check.report.id_to_selfie_matching.status] }}
                    </v-icon>
                    {{ status[check.report.id_to_selfie_matching.status] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="6">
                <v-list-item>
                  <div v-if="check.report.id_to_selfie_matching.status === true">
                    <v-icon color="success" class="mr-1" size="x-large">check_circle</v-icon>
                    <span class="span-text">Face Matched</span>
                  </div>
                  <div v-else-if="check.report.id_to_selfie_matching.status === false">
                    <v-icon color="red" class="mr-1" size="x-large">cancel</v-icon>
                    <span class="span-text">Face Not Matched</span>
                  </div>
                  <div v-else>
                    <v-icon class="mr-1" size="x-large">help</v-icon>
                    <span class="span-text">Incomplete</span>
                  </div>
                </v-list-item>
              </v-col>

              <v-col v-if="refFaceFrameUrl" md="2" class="text-center">
                <image-lazy :image-url="faceFrameUrl" lazy-image="face" aspect-ratio="1"
                            width="150px" height="150px" />
              </v-col>
              <v-col v-if="refFaceFrameUrl" md="1" class="text-center" align-self="center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="40">
                  <path v-if="check.report.id_to_selfie_matching.status"
                      d="M48 128c-17.7 0-32 14.3-32 32s14.3 32 32 32H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H48zm0
                      192c-17.7 0-32 14.3-32 32s14.3 32 32 32H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H48z"/>
                  <path v-else d="M369.8 37.4c14.7 9.8 18.7 29.7 8.9 44.4L337.1 144H400c17.7 0 32 14.3 32 32s-14.3 32-32
                  32H294.5l-64 96H400c17.7 0 32 14.3 32 32s-14.3 32-32 32H187.8l-65.2 97.7c-9.8 14.7-29.7 18.7-44.4
                  8.9s-18.7-29.7-8.9-44.4L110.9 368H48c-17.7 0-32-14.3-32-32s14.3-32 32-32H153.5l64-96H48c-17.7
                  0-32-14.3-32-32s14.3-32 32-32H260.2l65.2-97.7c9.8-14.7 29.7-18.7 44.4-8.9z"/>
                </svg>
              </v-col>
              <v-col v-if="refFaceFrameUrl" md="2" class="text-center">
                <image-lazy :image-url="refFaceFrameUrl" lazy-image="face" aspect-ratio="1"
                            width="150px" height="150px" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.personal_information_verification" style="height: 100%;">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Personal Information Verification</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[check.report.personal_information_verification.status]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[check.report.personal_information_verification.status] }}
                    </v-icon>
                    {{ status[check.report.personal_information_verification.status] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-list-item
                    v-for="[key, val] in Object.entries(check.report.personal_information_verification.report)"
                    :key="key"
                >
                  <span class="span-text">  {{ toTitleCase(key) }}: </span>
                  <span class="span-text ml-1">  {{ val }}</span>
                </v-list-item>
                <v-list-item>
                  <div v-if="check.report.personal_information_verification.status">
                    <v-icon color="success" class="mr-1" size="x-large">check_circle</v-icon>
                    <span class="span-text">Personal Information Verified</span>
                  </div>
                  <div v-else>
                    <v-icon color="red" class="mr-1" size="x-large">cancel</v-icon>
                    <span class="span-text">Personal Information Not Verified</span>
                  </div>
                </v-list-item>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.mobile_number_verification" style="height: 100%;">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Mobile Number Verification</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[check.report.mobile_number_verification.status]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[check.report.mobile_number_verification.status] }}
                    </v-icon>
                    {{ status[check.report.mobile_number_verification.status] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-list-item>
                  <span class="span-text">  Mobile Number: </span>
                  <span class="span-text ml-1">  {{ check.report.mobile_number_verification.report.mobile_number }}</span>
                </v-list-item>
                <v-list-item>
                  <span class="span-text">  Retries Left: </span>
                  <span class="span-text ml-1">  {{ check.report.mobile_number_verification.report.retries_left }}</span>
                </v-list-item>
                <v-list-item>
                  <div v-if="check.report.mobile_number_verification.status">
                    <v-icon color="success" class="mr-1" size="x-large">check_circle</v-icon>
                    <span class="span-text">Mobile Number Verified</span>
                  </div>
                  <div v-else>
                    <v-icon color="red" class="mr-1" size="x-large">cancel</v-icon>
                    <span class="span-text">Mobile Number Not Verified</span>
                  </div>
                </v-list-item>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.undertaking" style="height: 100%;">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Undertaking</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[check.report.undertaking.status]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[check.report.undertaking.status] }}
                    </v-icon>
                    {{ status[check.report.undertaking.status] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-list-item>
                  <div v-if="check.report.undertaking.status">
                    <v-icon color="success" class="mr-1" size="x-large">check_circle</v-icon>
                    <span class="span-text">Undertaking Accepted</span>
                  </div>
                  <div v-else>
                    <v-icon color="red" class="mr-1" size="x-large">cancel</v-icon>
                    <span class="span-text">Undertaking Not Accepted</span>
                  </div>
                </v-list-item>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.account_options" style="height: 100%;">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Account Options</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[check.report.account_options.status]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[check.report.account_options.status] }}
                    </v-icon>
                    {{ status[check.report.account_options.status] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-list-item>
                  <div>
                    <span class="span-text">  Suggested Account Type: </span>
                    <span class="span-text ml-1">  {{ check.report.account_options.report.type }}</span>
                  </div>
                </v-list-item>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.fingerprint_capture" style="height: 100%;">
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col md="10">
                <h3 class="panel-heading">Fingerprint Captures</h3>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-col>
              <v-col md="2">
                <div class="text-right">
                  <v-chip
                      class="ma-2 font-weight-bold"
                      :color="status_colors[check.report.fingerprint_capture.status]"
                      label
                      text-color="white"
                  >
                    <v-icon left class="font-weight-bold">
                      {{ status_icons[check.report.fingerprint_capture.status] }}
                    </v-icon>
                    {{ status[check.report.fingerprint_capture.status] }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-list-item>
                  <div>
                    <span class="span-text">  Retries: {{ check.report.fingerprint_capture.report.retries }}</span>
                  </div>
                </v-list-item>
              </v-col>
              <v-col cols="12">
                <v-list-item>
                  <div>
                    <span class="span-text">  Hand Type: {{ check.fingerprints['hand_type'] }}</span>
                  </div>
                </v-list-item>
              </v-col>
              <v-col cols="3"
                     class="text-center"
                     v-for="[key, val] in Object.entries(check.fingerprints)"
                     v-if="val && val['image']"
                     :key="key"
              >
                <span class="span-text">{{ toTitleCase(`${getHandName(key)} ${getFingerName(key)}`) }}</span>
                <image-lazy :image-url="val['image']"
                            lazy-image="finger" aspect-ratio="154/250" width="154px" height="250px" />
                <span class="span-text">  NFIQ: {{ getNFIQ(getHandName(key), getFingerName(key)) }}</span>
              </v-col>
<!--              <v-col cols="3"-->
<!--                     class="text-center"-->
<!--                     v-for="(finger, idx) in ['thumb', 'index', 'middle', 'ring', 'little']"-->
<!--                     v-if="check.fingerprints['finger_' + (fingerIdx + idx).toString()]['image']"-->
<!--                     :key="finger"-->
<!--              >-->
<!--                <span class="span-text">{{ toTitleCase(finger) }}</span>-->
<!--                <image-lazy :image-url="check.fingerprints['finger_' + (fingerIdx + idx).toString()]['image']"-->
<!--                            lazy-image="finger" aspect-ratio="154/250" width="154px" height="250px" />-->
<!--                <span class="span-text">  NFIQ: {{ check.report.fingerprint_capture.report[finger] }}</span>-->
<!--              </v-col>-->
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="check.report.document_capture">
          <v-expansion-panel-header>
            <h3 class="panel-heading">Customer Details</h3>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="4">
                <v-list-item>
                  <v-text-field
                      label="Name"
                      hide-details="auto"
                      v-model="extracted_data.Name"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                      label="ID Number"
                      hide-details="auto"
                      v-model="extracted_data['Identity Number']"
                  ></v-text-field>
                </v-list-item>
              </v-col>
              <v-col md="4" class="mb-1">
                <v-list-item>
                  <v-text-field
                      :label="father_or_husband_key"
                      hide-details="auto"
                      v-model="extracted_data[father_or_husband_key]"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                      label="Country of Stay"
                      hide-details="auto"
                      v-model="extracted_data['Country of Stay']"
                  ></v-text-field>
                </v-list-item>
              </v-col>
              <v-col md="4" class="mb-1">
                <v-list-item>
                  <v-text-field
                      label="Gender"
                      hide-details="auto"
                      v-model="extracted_data.Gender"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                      label="Date of Birth"
                      hide-details="auto"
                      v-model="extracted_data['Date of Birth']"
                  ></v-text-field>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-list-item>
                  <v-text-field
                      label="Date of Issue"
                      hide-details="auto"
                      v-model="extracted_data['Date of Issue']"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                      label="Date of Expiry"
                      hide-details="auto"
                      v-model="extracted_data['Date of Expiry']"
                  ></v-text-field>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="8">
                <v-list-item>
                  <v-text-field
                      class="rtl"
                      label="Temporary Address"
                      hide-details="auto"
                      v-model="extracted_data['Temporary Address']"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                      class="rtl"
                      label="Permanent Address"
                      hide-details="auto"
                      v-model="extracted_data['Permanent Address']"
                  ></v-text-field>
                </v-list-item>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row align="center">
        <v-col md="9">
        </v-col>
        <v-col md="3">
          <v-btn class="float-right mt-4" block
                 color="success"
                 outlined
                 rounded
                 @click="updateReport"
          >
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mt-10">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="12" md="10">
            <v-card-title>
              Session Changes
            </v-card-title>
          </v-col>
          <v-col cols="12" md="2">
            <v-checkbox v-model="systemHistory" label="Hide System Changes" />
          </v-col>
        </v-row>

        <v-data-table
            :headers="headers"
            :items="filteredHistoryItems"
            :sort-by.sync="sortByKey"
            :sort-desc.sync="sortDesc"
            :options="{itemsPerPage:8}"
            class="elevation-1"
        >
          <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ header, value }">
            {{ header.formatter(value) }}
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { latLng, Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import imageLazy from "./imageLazy";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    imageLazy
  },
  data(){
    return {
      zoom: 13,
      center: null,
      check: {},
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 9.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      historyItems: [],
      systemHistory: true,
      isVerificationOfficer: false,
      sortByKey: 'date',
      sortDesc: true,
      sessionId: this.$route.params.id,
      overlay: false,
      agentName: "",
      agentCnic: "",
      chosenAgent: false,
      extracted_data: {},
      referenceFrameUrl: null,
      backFrameUrl: null,
      faceFrameUrl: null,
      poseFrameUrl: null,
      agentFrameUrl: null,
      agentPoseFrameUrl: null,
      agentReferenceUrl: null,
      manualOverride: null,
      agentLiveVerified: null,
      father_or_husband_key: 'Father Name',
      panel: [],
      fingerTypes: ["thumb", "index", "middle", "ring", "little"],
      headers: [
        {
          text:"Date",
          align: "start",
          filterable: true,
          sortable: true,
          value: 'date',
          formatter: this.changeTimeFormat
        },
        {
          text:"Type",
          align: "start",
          filterable: true,
          sortable: true,
          value: 'type'
        },
        {
          text: 'Changes',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'changes',
        },

        {
          text: 'Changed By',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'user',
        },
      ],
      status_colors:{
        success:"success",
        true:"success",
        failed:"error",
        false:"error",
        "Verified":"success",
        "Not Verified":"error",
        doubtful:"warning",
        working:"warning",
        Pending: "warning",
        pending: "warning",
        Incomplete: "grey",
        null: "grey",
        not_started: "error",
        "manually authenticated": "warning",
        "manually authenticated by VO": "warning"
      },
      status_icons:{
        success:"done",
        true:"done",
        failed:"error",
        false:"close",
        "Verified":"done",
        "Not Verified":"error",
        doubtful:"warning",
        working:"warning",
        Pending: "warning",
        pending: "warning",
        Incomplete: "question_mark",
        not_started: "error",
        "manually authenticated": "warning",
        "manually authenticated by VO": "warning",
        null: "question_mark",
      },
      icons: {
        "done": { icon_color: "success", icon_text: "check_circle" },
        "true": { icon_color: "success", icon_text: "check_circle" },
        "failed": { icon_color: "error", icon_text: "error" },
        "false": { icon_color: "error", icon_text: "error" },
        "error": { icon_color: "error", icon_text: "error" },
        "not sure": { icon_color: "warning", icon_text: "remove_circle" },
        "warning": { icon_color: "warning", icon_text: "remove_circle" },
        "working": { icon_color: "warning", icon_text: "remove_circle" },
        "pending": { icon_color: "warning", icon_text: "remove_circle" },
        "null": { icon_color: "white", icon_text: "question" },
      },
      status: {
        "true" : "Verified",
        "success": "Verified",
        "false": "Not Verified",
        "working": "Working",
        "pending": "Pending",
        "Pending": "Pending",
        "manually authenticated": "Manually Verified",
        "manually authenticated by VO": "Manually Verified",
        "null": "Incomplete"
      },
      changeTypes: {
        '~': 'Update',
        '+': 'Create',
        '-': 'Delete',
      },
      document_authenticity: {},
      document_capture: {},
      face_liveness: {},
      agent_verification: {},
      agent_face_present: { icon_color: "warning", icon_text: "remove_circle" },
      agent_liveness: { icon_color: "warning", icon_text: "remove_circle" },
      agent_face_match: { icon_color: "warning", icon_text: "remove_circle" },
    }
  },
  mounted() {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(window.localStorage.getItem('user'))
      if (user && user.verification_officer !== null) {
        this.isVerificationOfficer = true
      }
    }
    this.overlay = true
    this.$http.get("/sessions/"+this.$route.params.id+"/?delete_fingerprints=False").then(res=>{
      this.loadReport(res)
      this.overlay = false
      this.setNotification({
        message: "Report successfully loaded",
        color: 'success'
      })
    }).catch(error => {
      this.setNotification({
        message: error,
        color: 'error'
      })
      this.overlay = false
    })
    this.getSessionHistory()
  },
  methods:{
    ...mapActions(["setNotification"]),
    loadReport(res) {
      this.check = res.data
      if (this.check.report.latitude && this.check.report.longitude) {
        this.center = latLng(this.check.report.latitude, this.check.report.longitude)
      }
      if (Object.keys(this.check.report).includes('agent_verification') && this.check.chosen_agent) {
        this.agentName = this.check.chosen_agent.name
        this.agentCnic = this.check.chosen_agent.cnic
        this.agentReferenceUrl = this.check.chosen_agent.image
        this.chosenAgent = this.check.chosen_agent
      }
      // open panels if status is not verified
      this.panel.push(0)
      const panelKeys = ["agent_verification", "face_liveness", "document_capture", "document_backside_capture",
            "document_authenticity", "id_to_selfie_matching", "personal_information_verification",
            "mobile_number_verification", "agent_verification", "undertaking", "account_options", "fingerprint_capture"]
      let index = 1
      for (const key of panelKeys) {
        const val = this.check.report[key]
        if (val !== null && typeof val === "object") {
          console.log(key, index)
          if ([false, "Pending", "pending"].includes(val.status)) {
            this.panel.push(index)
          }
          if (key === "document_authenticity" && this.check.authentication_frames) {
            // If there is a panel for authentication frames, increment panel index for next panels by 1
            index += 1
          }
          if (key === "document_capture" && this.check.tamper_frames) {
            // If there is a panel for authentication frames, increment panel index for next panels by 1
            index += 1
          }
          index += 1
        }
      }
      this.panel.push(index)

      if (this.check.report.document_capture && this.check.report.document_capture.report.extracted_data) {
        this.extracted_data = this.check.report.document_capture.report.extracted_data
        if (Object.keys(this.extracted_data).includes('Husband Name')) {
          this.father_or_husband_key = 'Husband Name'
        }
      }
      this.referenceFrameUrl = this.check.reference_frame
      this.backFrameUrl = this.check.back_frame
      this.poseFrameUrl = this.check.pose_frame
      this.faceFrameUrl = this.check.face_frame
      this.agentFrameUrl = this.check.agent_frame
      this.agentPoseFrameUrl = this.check.agent_pose_frame
      if (this.check.report.agent_verification) {
        this.agentLiveVerified = this.check.report.agent_verification.status
      }
      this.agent_face_match = this.icons[this.agentLiveVerified]
      if (this.agentFrameUrl) {
        this.agent_face_present = this.icons["done"]
        this.agent_liveness = this.icons["done"]
      } else {
        this.agent_face_present = this.icons["failed"]
        this.agent_liveness = this.icons["failed"]
      }
      this.getFieldStatus()
    },
    getSessionHistory() {
      this.$http.get("/session-history/"+this.$route.params.id+"/").then(res=>{
        this.historyItems = []
        if (res.data.length > 0) {
          let allData = res.data
          allData.forEach(data => {
            this.historyItems.push({
              date: data.date,
              type: this.changeTypes[data.type],
              user: data.user,
              changes: this.getChangesString(data.changes)
            })
          })
        }
      })
    },
    // verify() {
    //   Object.keys(this.document_report).forEach(key => {
    //     this.verifyField(key)
    //   })
    // },
    changeTimeFormat(value) {
      const date = new Date(value)
      return date.toDateString() + ", " + date.toLocaleTimeString()
    },
    getChangesString(changes) {
      let changeString = ''
      if (changes) {
        for (let [key, val] of Object.entries(changes)) {
          if (val.new_value !== val.old_value) {
            changeString += `${key} updated from ${val.old_value !== ''? val.old_value: 'null'} to ${val.new_value !== ''? val.new_value: 'null'}\n`
          }
        }
      }
      return changeString
    },
    getFieldStatus() {
      this.getStatusFrom('document_capture')
      this.getStatusFrom('document_authenticity')
      this.getStatusFrom('face_liveness')
      this.getStatusFrom('agent_verification')
    },
    getStatusFrom(reportType) {
      if (this.check.report && this.check.report[reportType]) {
        let report = {}
        if (reportType === 'document_capture') {
          report = this.check.report[reportType].report.document_report
        } else {
          report = this.check.report[reportType].report
        }
        // console.log(report)
        if (report && report.length > 0) {
          report.forEach(x => {
            if (x && x.children) {
              x.children.forEach(y => {
                this[reportType][y.key] = Object.assign({}, this.icons[y.value])
                if (y.message) {
                  console.log(y.key)
                  this[reportType][y.key]["message"] = y.message
                }
              })
            }
          })
        }
      }
    },
    toTitleCase(keyName) {
      return keyName.split('_')
                .map(w => w.trim()[0].toUpperCase() + w.trim().substr(1).toLowerCase())
                .join(' ')
    },
    getHandName(keyName) {
      const fIdx = parseInt(keyName.split('_')[1])
      return fIdx > 5? "left": "right"
    },
    getFingerName(keyName) {
      const fIdx = parseInt(keyName.split('_')[1])
      const diff = fIdx > 5? 6: 1
      return this.fingerTypes[fIdx - diff]
    },
    getNFIQ(hand, finger) {
      const finger_hand = `${hand}_${finger}`
      const fingerCaptureReport = this.check.report.fingerprint_capture.report
      return fingerCaptureReport && Object.keys(fingerCaptureReport).includes(finger_hand)?
          fingerCaptureReport[finger_hand]: fingerCaptureReport[finger]
    },
    // verifyField(fieldName) {
    //   let tempReport = this.document_report
    //   this.document_report = {}
    //   let status = ''
    //   console.log(tempReport[fieldName].icon_color)
    //   if (tempReport[fieldName].icon_color === 'success') {
    //     status = 'warning'
    //   } else status = tempReport[fieldName].icon_color !== 'warning';
    //   tempReport[fieldName] = this.icons[status]
    //   this.document_report = tempReport
    //   this.check.report.document_report.forEach(x => {
    //     x.children.forEach(y => {
    //       if (fieldName === y.key) {
    //         y.value = status
    //       }
    //     })
    //   })
    //   console.log(this.document_report)
    // },
    changeDocumentStatus(manualOverride) {
      this.overlay = true
      this.manualOverride = manualOverride
      console.log('-- manually override --')
      let formData = new FormData();
      formData.append('session', this.sessionId);
      formData.append('manual_override', manualOverride);
      this.$http.post("/change-document-status", formData).then(r => {
        console.log(r)
        this.$http.get("/sessions/"+this.$route.params.id).then(res=>{
          this.loadReport(res)
        })
        this.getSessionHistory()
        this.overlay = false
      }).catch(error => {
        this.overlay = false
        if (typeof error.response.data === 'object') {
          error = error.response.data
          if ('non_field_errors' in error) {
            error = `${error['non_field_errors'][0]}`
          }
        }
        this.setNotification({
          message: error,
          color: 'error'
        })
      })
    },
    updateReport() {
      this.check.report.document_capture.report.extracted_data = this.extracted_data
      // if (this.check.report.document_authenticity) {
      //   if (this.manualOverride === true) {
      //     this.check.report.document_authenticity.status = "manually authenticated by VO"
      //   } else if (this.manualOverride === false) {
      //     this.check.report.document_authenticity.status = false
      //   }
      // }
      this.$http.patch(`/sessions/${this.sessionId}/`, this.check).then(res=> {
        console.log(res)
        this.setNotification({
          message: "Report successfully updated",
          color: 'success'
        })
        this.getSessionHistory()
      }).catch(error => {
        this.setNotification({
          message: error,
          color: 'error'
        })
      })
    }
  },
  computed: {
    customerLiveVerified() {
      if (this.check.report.face_liveness) {
        return this.check.report.face_liveness.status
      }
      return null
    },
    fingerIdx() {
      if (this.check.fingerprints && this.check.fingerprints.hand_type) {
        return this.check.fingerprints.hand_type.includes("left")? 6: 1
      }
      return 1
    },
    customerDocVerified() {
      if (this.check.report.document_authenticity) {
        return this.check.report.document_authenticity.status
      }
      return null
    },
    overAllStatus () {
      return this.check.status
    },
    filteredHistoryItems() {
      if (this.systemHistory) {
        return this.historyItems.filter(x => x.user !== 'Auto')
      }
      return this.historyItems
    },
    qrCode() {
      return this.check.report.document_backside_capture && this.check.report.document_backside_capture.report.qr_data?
          this.check.report.document_backside_capture.report.qr_data: "Not Found"
    },
    refFaceFrameUrl() {
      return this.check.reference_face_image
    }
  }
};
</script>
<style lang="scss">
.justify-content-center {
  justify-content: center;
}
h3, .v-card__title {
  color: #004aad;
}
.bg-success {
  color: white !important;
  background-color: #4caf50;
}
.bg-fail {
  color: white !important;
  background-color: red;
}
//.not-clickable {
//  color: gray !important;
//}
//.not-clickable .success--text {
//  color: lightgreen !important;
//}
.clickable {
  border: 1px black;
  border-radius: 5px;
  margin-bottom: 5px;
}
table tbody tr td {
  white-space: pre-wrap;
}
td{
  text-overflow: ellipsis; // this is producing "..."
  white-space: nowrap;
  overflow: hidden;
}
.rtl {
  direction: rtl;
}
//.panel-heading {
//  font-weight: bold;
//  font-size: 30px;
//  color: #004aad;
//}
//.span-text {
//  font-size: 20px;
//  color: #3961c9;
//  margin-left: 10px;
//}
//.v-list-item {
//  align-items: center;
//  display: flex;
//  flex: 1 1 100%;
//  letter-spacing: normal;
//  min-height: 48px;
//  outline: none;
//  padding: 10px 16px !important;
//  position: relative;
//  text-decoration: none;
//}
</style>
